import { Routes } from '@angular/router';
import { isLoggedGuard } from './common/guards/is-logged.guard';

export const routes: Routes = [
    {
        path: 'app',
        loadChildren: () => import('./home/home.routes').then(m => m.homeRoutes),
        canActivate:[
            isLoggedGuard
        ]
    },
   {
        path: 'auth',
        loadChildren: () => import('./auth/auth.routes').then(m => m.authRoutes),
    },
    {
        path: 'play',
        loadChildren: () => import('./display/display.routes').then(m => m.displayRoutes)
    },
    {
        path: 'obituary',
        loadChildren: () => import('./display/display.routes').then(m => m.displayRoutes)
    },
    {
        path: 'obit',
        loadChildren: () => import('./deceased/deceased.routes').then(m => m.obitRoutes)
    },
    {
        path: 'test',
        loadComponent: () => import('./shared/components/test/test.component').then(m => m.TestComponent)
    },
    {
        path: '',
        redirectTo: 'app',
        pathMatch: 'full'
    } 
];