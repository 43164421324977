import { Component, inject, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { AuthService } from './auth/services/auth.service';
import { HasRoleDirective } from './common/directives/has-role.directive.directive';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    ToastModule,
    HasRoleDirective
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  providers: [
    MessageService,
    ConfirmationService
  ]
})
export class AppComponent implements OnInit {

  authService = inject(AuthService);
  ngOnInit(): void {
    this.authService.checkAuthStatus();
  }

}
