import { HttpErrorResponse, type HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { tap } from 'rxjs';
import { AuthService } from '../../auth/services/auth.service';
import { environment } from '../../../environments/environment';

export const JwtInterceptor: HttpInterceptorFn = (request, next) => {
  
  const router = inject(Router)
  const authService = inject(AuthService)
  // except for /login endpoint
  if (request.url.includes('/api/v1/auth/sign-in') || request.url.includes('geolocation-db')) {
    return next(request);
  }
  // edit request
  request = request.clone({
    setHeaders: {
      Authorization: `Bearer ${localStorage.getItem("jwt-trueke")}`,
      'x-api-key': environment.apiKey
    }
  });
  return next(request)
    .pipe(
      tap({
        error: (err) => {
          if (err instanceof HttpErrorResponse) {

            if (err.status === 401) {
              // redirect to login page
              console.warn('Sesión expirada. Vuelva a iniciar sesión.');
              authService.authLogout()
              router.navigateByUrl('/auth/login');
            }

          }
        },
      })
    )
};
